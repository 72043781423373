import {IconAlertCircle, IconX} from '@tabler/icons-react';
import Cookies from 'js-cookie';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {apiUrl} from '../../routes/AdminRoutes';
import CommonBtn from './CommonBtn';
import ContentEditor from './ContentEditor';
import CoverUploader from './CoverUploader';

const TranslateButton = ({cnVersion, onTranslationComplete}) => {
  const [isTranslating, setIsTranslating] = useState(false);
  const [translateError, setTranslateError] = useState('');

  const genEnVersion = async () => {
    setIsTranslating(true);
    setTranslateError('');
    try {
      const response = await fetch(`${apiUrl}/api/translate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Session-ID': Cookies.get('sessionId'),
        },
        body: JSON.stringify(cnVersion),
      });

      if (!response.ok) {
        throw new Error('生成英文版本失败');
      }

      const englishVersion = await response.json();
      const tagsArray = JSON.parse(englishVersion.data.tags.replace(/'/g, '"'));

      onTranslationComplete({
        ...englishVersion.data,
        tags: tagsArray,
      });
    } catch (error) {
      console.error('生成英文版本时出错:', error);
      setTranslateError('生成英文版本失败，请稍后重试');
    } finally {
      setIsTranslating(false);
    }
  };

  return (
    <>
      <CommonBtn
        onClick={genEnVersion}
        variant='primary'
        disabled={isTranslating}
        loading={isTranslating}
        loadingText='正在翻译中...'>
        从中文原文生成英文版本
      </CommonBtn>
      {translateError && (
        <div className='flex items-center mt-2 text-red-500'>
          <IconAlertCircle
            size={16}
            className='mr-1'
          />
          <span>{translateError}</span>
        </div>
      )}
    </>
  );
};

const TitleInput = ({title, setTitle}) => (
  <input
    type='text'
    value={title}
    onChange={(e) => setTitle(e.target.value)}
    className='flex-grow h-10 px-3 py-2 border rounded-md border-neutral-500 placeholder-neutral-500'
    placeholder='请输入文章标题'
  />
);

const TagManager = ({tags, setTags}) => {
  const [currentTag, setCurrentTag] = useState('');

  const addTag = () => {
    if (currentTag && !tags.includes(currentTag)) {
      setTags([...tags, currentTag]);
      setCurrentTag('');
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  return (
    <div className='space-y-4'>
      <div className='flex items-center flex-wrap overflow-x-auto min-h-[40px] p-1 border rounded-md border-neutral-500'>
        {tags.map((tag) => (
          <span
            key={tag}
            className='flex items-center h-8 px-2 m-1 text-sm rounded text-neutral-700 bg-neutral-100 whitespace-nowrap'>
            {tag}
            <button
              onClick={() => removeTag(tag)}
              className='ml-1 text-neutral-500 hover:text-neutral-700'>
              <IconX size={14} />
            </button>
          </span>
        ))}
      </div>
      <div className='flex items-center'>
        <input
          type='text'
          value={currentTag}
          onChange={(e) => setCurrentTag(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && addTag()}
          className='flex-grow h-10 px-3 mr-2 border rounded-md border-neutral-500 placeholder-neutral-500'
          placeholder='输入新标签'
        />
        <CommonBtn
          onClick={addTag}
          variant='secondary'
          size='small'>
          添加标签
        </CommonBtn>
      </div>
    </div>
  );
};

const ArticleForm = ({article, onNewArticle, onSave}) => {
  const [articleId, setArticleId] = useState('');
  const [cnVersion, setCnVersion] = useState({title: '', content: '', tags: []});
  const [enVersion, setEnVersion] = useState(null);
  const [image, setImage] = useState(null);
  const [saveStatus, setSaveStatus] = useState('');
  const [published, setPublished] = useState(false);
  const [activeTab, setActiveTab] = useState('chinese');

  const contentEditorRef = useRef(null);

  useEffect(() => {
    if (article) {
      setArticleId(article.id || uuidv4());
      setImage(article.image);
      setPublished(article.published);
      setCnVersion({
        title: article.cn.title,
        content: article.cn.content.replace(/^"|"$/g, ''), // 移除开头和结尾的引号
        tags: article.cn.tags,
      });
      if (article.en && Object.keys(article.en).length > 0) {
        setEnVersion({
          title: article.en.title,
          content: article.en.content.replace(/^"|"$/g, ''), // 移除开头和结尾的引号
          tags: article.en.tags,
        });
      } else {
        setEnVersion(null);
      }
    } else {
      setArticleId(uuidv4());
      resetForm();
    }
  }, [article]);

  const resetForm = () => {
    setCnVersion({title: '', content: '', tags: []});
    setEnVersion(null);
    setImage(null);
    setPublished(false);
  };

  const handleNewArticle = () => {
    resetForm();
    onNewArticle();
  };

  const handleTranslationComplete = (englishVersion) => {
    setEnVersion(englishVersion);
  };

  const handleArticleSubmit = async (isPublish = false) => {
    const getContent = () => {
      if (contentEditorRef.current && contentEditorRef.current.getHTML) {
        return contentEditorRef.current.getHTML();
      }
      return activeVersion.content;
    };

    const articleData = {
      id: articleId,
      image,
      date: new Date(),
      published: isPublish,
      cn: {
        ...cnVersion,
        content: activeTab === 'chinese' ? getContent() : cnVersion.content,
      },
    };

    if (enVersion) {
      articleData.en = {
        ...enVersion,
        content: activeTab === 'english' ? getContent() : enVersion.content,
      };
    }

    const result = await onSave(articleData, isPublish);

    if (result.success) {
      setSaveStatus('success');
      // console.log(result.message);
    } else {
      setSaveStatus('error');
      console.error(result.message);
    }

    setTimeout(() => setSaveStatus(''), 3000);
  };

  const activeVersion = useMemo(() => {
    return activeTab === 'chinese' ? cnVersion : enVersion;
  }, [activeTab, cnVersion, enVersion]);

  return (
    <div className='flex flex-col w-full h-full m-4 overflow-hidden'>
      <div className='flex mb-6 border-b'>
        <button
          className={`py-2 px-4 ${activeTab === 'chinese' ? 'border-b-2 border-blue-500' : ''}`}
          onClick={() => setActiveTab('chinese')}>
          中文版本
        </button>
        <button
          className={`py-2 px-4 ${activeTab === 'english' ? 'border-b-2 border-blue-500' : ''}`}
          onClick={() => setActiveTab('english')}>
          {enVersion ? '英文版本' : '英文版本（待翻译）'}
        </button>
      </div>
      <div className='flex mb-6 space-x-6'>
        <div className='w-1/3'>
          {activeTab === 'chinese' ? (
            <CoverUploader
              image={image}
              setImage={setImage}
              articleId={articleId}
            />
          ) : (
            <div className='flex items-center justify-center h-full'>
              <TranslateButton
                cnVersion={cnVersion}
                onTranslationComplete={handleTranslationComplete}
              />
            </div>
          )}
        </div>
        <div className='flex flex-col justify-between w-2/3'>
          {(activeTab === 'chinese' || enVersion) && (
            <div className='space-y-4'>
              <div className='flex items-center space-x-4'>
                {activeVersion && (
                  <div className='flex items-center flex-grow'>
                    <TitleInput
                      title={activeVersion.title}
                      setTitle={(newTitle) =>
                        activeTab === 'chinese'
                          ? setCnVersion({...cnVersion, title: newTitle})
                          : setEnVersion({...enVersion, title: newTitle})
                      }
                    />
                    {published && (
                      <span className='px-2 py-1 ml-2 text-xs font-semibold text-green-800 bg-green-100 rounded-full'>
                        已发布
                      </span>
                    )}
                  </div>
                )}
                <CommonBtn
                  onClick={() => handleArticleSubmit(false)}
                  variant={
                    saveStatus === 'success'
                      ? 'success'
                      : saveStatus === 'error'
                      ? 'danger'
                      : 'secondary'
                  }
                  disabled={saveStatus !== ''}>
                  {saveStatus === 'success'
                    ? '保存成功'
                    : saveStatus === 'error'
                    ? '保存失败'
                    : '保存草稿'}
                </CommonBtn>
                <CommonBtn
                  onClick={() => handleArticleSubmit(true)}
                  variant='primary'>
                  {published ? '更新' : '发布'}
                </CommonBtn>
                <CommonBtn
                  onClick={handleNewArticle}
                  variant='success'>
                  新建
                </CommonBtn>
              </div>
              {activeVersion && (
                <TagManager
                  tags={activeVersion.tags}
                  setTags={(newTags) =>
                    activeTab === 'chinese'
                      ? setCnVersion({...cnVersion, tags: newTags})
                      : setEnVersion({...enVersion, tags: newTags})
                  }
                  placeholder='文章标签'
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className='flex-grow overflow-hidden'>
        {activeVersion && (
          <ContentEditor
            ref={contentEditorRef}
            content={activeVersion.content}
            setContent={(newContent) =>
              activeTab === 'chinese'
                ? setCnVersion({...cnVersion, content: newContent})
                : setEnVersion({...enVersion, content: newContent})
            }
            articleId={articleId}
          />
        )}
      </div>
    </div>
  );
};

export default ArticleForm;
